// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]5.5-rc-9393(999c63303e)-C05/07/2023-17:18:19-B05/07/2023-17:19:39' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]5.5-rc-9393(999c63303e)-C05/07/2023-17:18:19-B05/07/2023-17:19:39",
  branch: "master",
  latestTag: "5.5-rc",
  revCount: "9393",
  shortHash: "999c63303e",
  longHash: "999c63303ebae4c6e23daa8a865c27c62e2ca9cf",
  dateCommit: "05/07/2023-17:18:19",
  dateBuild: "05/07/2023-17:19:39",
  buildType: "Ansible",
  } ;
