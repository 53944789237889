import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {combineLatest, Subscription} from "rxjs";
import {UtilsService} from "../../../core/utils/utils.service";
import {SiteDTO} from "../../../core/dtos/site-dto";
import {Auth2Service} from "../../../core/services/security/auth2.service";
import {MSG_KEY, MSG_SEVERITY} from "../../../core/constants";
import {ToastService} from "../../../core/services/technique/toast.service";
import {InternationalizationService} from "../../../core/services/i8n/i8n.service";
import {OrganisationsTourneesService} from "../../../core/services/gestion-production/organisations-tournees.service";
import {OrganisationTourneeInfoDto} from "../../../core/dtos/organisation-tournee-info-dto";
import {RoundPeriodsDto} from "../../../core/dtos/round-periods-dto";
import {Tournee} from "../../../core/dtos/tournee-dto";
import {GraphQLService} from "../../../core/services/technique/graphql.service";
import {PointDeLivraisonService} from "../../../core/services/entities/point-de-livraison.service";
import {PointDeLivraisonDTO} from "../../../core/dtos/point-de-livraison-d-t-o";
import {SearchSupplierWrapper} from "../../../core/suppliers/wrappers/search-supplier-wrapper";
import {SearchSupplier} from "../../../core/suppliers/search-supplier";
import {FiltersContextOrganization} from "../../../core/dtos/filters-context-organization-dto";
import {RepasDTO} from "../../../core/dtos/repas-dto";
import {ContratMenuConviveDTO} from "../../../core/dtos/contratmenuconvive-dto";
import {TourneesService} from "../../../core/services/referentiel/tournees.service";
import {DxSelectBoxComponent} from "devextreme-angular";

@Component({
  selector: 'yo-organisation-tournee-dialog',
  templateUrl: './organisation-tournee-dialog-edition.component.html',
  styleUrls: ['./organisation-tournee-dialog-edition.component.scss']
})
export class OrganisationTourneeDialogComponent implements OnInit, OnDestroy {

  private subOpenDialog$: Subscription;

  private subFilters$: Subscription;

  displayDialog: boolean = false;

  dialogTitle: string;

  idSelectedSite: number;

  sitePlaceholder: string;

  organisationTournees: OrganisationTourneeInfoDto;

  fullScreen: boolean = true;

  selectedRowsPeriods: number[] = [];

  displayPopupPeriods: boolean = false;

  displayPopupAddTournee: boolean = false;

  dateStart: Date = new Date();

  dateEnd: Date = new Date();

  deliveryDays: string[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  tournees: Tournee[] = [];

  repas: RepasDTO[] = [];

  prestations: ContratMenuConviveDTO[] = [];

  pointsLivraisons: PointDeLivraisonDTO[] = [];

  filtersCtx: FiltersContextOrganization;

  plcSelected: PointDeLivraisonDTO;

  currentConsumptionDays: string[] = [];

  labelTourneeToCreate: string;

  poidsMaxTourneeToCreate: number = 0;

  @ViewChild("inputTournees") inputTournees: DxSelectBoxComponent;

  constructor(private orgTourneeSvc: OrganisationsTourneesService,
              private utilsSvc: UtilsService,
              private auth2Svc: Auth2Service,
              private toastSvc: ToastService,
              private graphQlSvc: GraphQLService,
              private tourneeSvc: TourneesService,
              private plcSvc: PointDeLivraisonService,
              private i8nSvc: InternationalizationService) {
  }

  ngOnInit() {
    this.openDialogSubscription();
  }

  ngOnDestroy(): void {
    this.utilsSvc.unsubscribe(this.subOpenDialog$);
    this.utilsSvc.unsubscribe(this.subFilters$);
  }

  loadTournees = (): void => {
    this.graphQlSvc.sendQuery(`
            {
              allTournees(filters: {
                siteIds: [${this.auth2Svc.utilisateur.sites.map(s => s.id)}],
                actif: true
              }) {
                  id,
                  libelle
              }
            }
          `)
      .toPromise()
      .then(response => this.tournees = response.allTournees);
  }

  loadFilters = (): void => {
    this.loadTournees();
    const ssw: SearchSupplierWrapper = new SearchSupplierWrapper();
    ssw.filtersMap['sites'] = new SearchSupplier(undefined, this.auth2Svc.utilisateur.sites.map(s => s.id));
    this.subFilters$ = combineLatest([
      this.plcSvc.getPlcWithoutMcPlcList(null, null, ssw),
      this.orgTourneeSvc.fetchFiltersContext()
    ]).subscribe(res => {
        this.filtersCtx = res[1].one;

        this.pointsLivraisons = [...new Map((res[0].resultList || []).map((item: PointDeLivraisonDTO) => [item["id"], item])).values()] as PointDeLivraisonDTO[];

        this.pointsLivraisons = this.pointsLivraisons.filter(plc => Object.keys(this.filtersCtx.allConsumptionDaysByPlc).includes(`${plc.id}`));

        this.repas = this.filtersCtx.allPrestationRoundContext.map(item => ({ id: item.idRepas, libelle: item.labelRepas } as RepasDTO));
        this.repas = [...new Map((this.repas).map((item: RepasDTO) => [item["id"], item])).values()] as RepasDTO[];

        this.prestations = this.filtersCtx.allPrestationRoundContext.map(item => ({ id: item.prestation.id, libelle: item.prestation.libelle } as ContratMenuConviveDTO));
        this.prestations = [...new Map((this.prestations).map((item: ContratMenuConviveDTO) => [item["id"], item])).values()] as ContratMenuConviveDTO[];
      });
  }

  openDialogAddTournee = (): void => {
    if (this.idSelectedSite)
      this.displayPopupAddTournee = true;
  }

  save = (): void => {
  }

  openDialogSubscription = (): void => {
    this.subOpenDialog$ = this.orgTourneeSvc.openDialogEdition$
      .subscribe((id: number) => {
        this.loadFilters();

        this.displayDialog = true;
        if (!id) {
          this.organisationTournees = new OrganisationTourneeInfoDto();
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_CREATION_ORGANIZATION_ROUND', null);
        } else {
          this.dialogTitle = this.i8nSvc.getLabelFromCode('TITLE_MODIFICATION_ORGANIZATION_ROUND', null);
        }
      });
  }

  findAllLocalSites = (): SiteDTO[] => {
    return this.auth2Svc.utilisateur.siteListLocaux;
  }

  closeDialog = (): void => {
    this.displayDialog = false;
  }

  onChangeSite = ($event: any): void => {
    this.idSelectedSite = $event.selectedItem?.id;
  }

  openDialogPeriods = (): void => {
    this.displayPopupPeriods = true;
  }

  closeDialogPeriods = (): void => {
    this.displayPopupPeriods = false;
  }

  addTournee = (): void => {
    this.tourneeSvc.save({ site: {id: this.idSelectedSite } as SiteDTO, actif: true, libelle: this.labelTourneeToCreate, poidsMaxEssieux: this.poidsMaxTourneeToCreate } as Tournee)
      .subscribe((res) => {
        this.loadTournees();
        this.labelTourneeToCreate = null;
        this.poidsMaxTourneeToCreate = 0;
        this.displayPopupAddTournee = false;
        this.inputTournees.instance.open();
      });
  }

  addPeriod = (): void => {
    if (!this.organisationTournees.periods.find(o => o.start === this.dateStart.getTime() && o.end === this.dateEnd.getTime() ))
      this.organisationTournees.periods.push({ start: this.dateStart.getTime(), end: this.dateEnd.getTime() } as RoundPeriodsDto);
    this.displayPopupPeriods = false;
  }

  onChangeTournee = ($event): void => {

  }

  onChangePointLivraisonClient = ($event): void => {
    this.plcSelected = $event.value;
    this.currentConsumptionDays = this.filtersCtx.allConsumptionDaysByPlc[this.plcSelected.id];
    this.currentConsumptionDays = this.currentConsumptionDays.map(c => {
      let key: number = 1;
      switch(c) {
        case 'LUNDI':
          key = 1;
          break;
        case 'MARDI':
          key = 2;
          break;
        case 'MERCREDI':
          key = 3;
          break;
        case 'JEUDI':
          key = 4;
          break;
        case 'VENDREDI':
          key = 5;
          break;
        case 'SAMEDI':
          key = 6;
          break;
        case 'DIMANCHE':
          key = 7;
          break;
        default:
          break;
      }
      return { key, label: c.charAt(0).toUpperCase() + c.slice(1).toLowerCase() };
    })
      .sort((d1, d2) => d1.key - d2.key)
      .map(d => d.label);
  }

  onChangeJourLivraison = ($event): void => {

  }

  onChangeJourConso = ($event): void => {

  }

  onChangeRepas = ($event): void => {
    console.log($event);
    // TODO car en changeant le repas, on ne garde que les prestations ayant ce repas
  }

  onChangePrestation = ($event): void => {
    console.log($event);
    // Désactiver tous les autres si Tous sélectionné
    this.repas = this.filtersCtx.allPrestationRoundContext.filter(p => this.prestations.find(pp => pp.id === p.id)).map(item => ({ id: item.idRepas, libelle: item.labelRepas } as RepasDTO));
    this.repas = [...new Map((this.repas).map((item: RepasDTO) => [item["id"], item])).values()] as RepasDTO[];
  }

}
